@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {

  font-family: 'Space Grotesk', sans-serif;
}


button, a, .hoverable {
  transition: all 0.2s ease; 
}